const constants = {
    app_name: "Network Black Hole",
    domain: "networkblackhole.com",
    web_url: "www.networkblackhole.com",
    app_url: "app.networkblackhole.com",
    privacy_email: "privacy@networkblackhole.com",
    analytics_url: "https://analytics.smartnetworkingsolutions.uk",
    defaultIPv4NextHop: "192.0.2.1",
    defaultIPv6NextHop: "100::1",
};

function useConstants() {
    return constants;
}

export { useConstants };
