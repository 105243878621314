import Vue from "vue";
import VueCompositionAPI from "@vue/composition-api";
Vue.use(VueCompositionAPI);

import { useConstants } from "@/const/const.js";
const constants = useConstants();

import App from "@/App.vue";
import { router } from "@/router";
// import store from "@/store";
import vuetify from "@/plugins/vuetify";

import "@mdi/font/css/materialdesignicons.css";

import "./assets/global.css";

import axios from "axios";
// README https://stackoverflow.com/questions/68256028/forbidden-csrf-cookie-not-set-when-sending-post-delete-request-from-vue-js-to
axios.defaults.xsrfHeaderName = "x-csrftoken";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.withCredentials = true;
Vue.prototype.$http = axios;

import VueMatomo from "vue-matomo";

Vue.use(VueMatomo, {
    // Configure your matomo server and site by providing
    host: constants.analytics_url,
    siteId: 3,

    // Changes the default .js and .php endpoint's filename
    // Default: 'matomo'
    trackerFileName: "matomo",

    // Overrides the autogenerated tracker endpoint entirely
    // Default: undefined
    // trackerUrl: 'https://example.com/whatever/endpoint/you/have',

    // Overrides the autogenerated tracker script path entirely
    // Default: undefined
    // trackerScriptUrl: 'https://example.com/whatever/script/path/you/have',

    // Enables automatically registering pageviews on the router
    router: router,

    // Enables link tracking on regular links. Note that this won't
    // work for routing links (ie. internal Vue router links)
    // Default: true
    enableLinkTracking: true,

    // Require consent before sending tracking information to matomo
    // Default: false
    // requireConsent: true,
    requireConsent: false,

    // Whether to track the initial page view
    // Default: true
    trackInitialView: true,

    // Run Matomo without cookies
    // Default: false
    disableCookies: false,

    // Require consent before creating matomo session cookie
    // Default: false
    // requireCookieConsent: true,
    requireCookieConsent: false,

    // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
    // Default: false
    enableHeartBeatTimer: false,

    // Set the heartbeat timer interval
    // Default: 15
    heartBeatTimerInterval: 15,

    // Whether or not to log debug information
    // Default: false
    debug: true,
    // debug: false,

    // UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
    // Default: undefined
    userId: undefined,

    // Share the tracking cookie across subdomains (see https://developer.matomo.org/guides/tracking-javascript-guide#measuring-domains-andor-sub-domains)
    // Default: undefined, example '*.example.com'
    cookieDomain: undefined,

    // Tell Matomo the website domain so that clicks on these domains are not tracked as 'Outlinks'
    // Default: undefined, example: '*.example.com'
    domains: undefined,

    // A list of pre-initialization actions that run before matomo is loaded
    // Default: []
    // Example: [
    //   ['API_method_name', parameter_list],
    //   ['setCustomVariable','1','VisitorType','Member'],
    //   ['appendToTrackingUrl', 'new_visit=1'],
    //   etc.
    // ]
    preInitActions: [],

    // A function to determine whether to track an interaction as a site search
    // instead of as a page view. If not a function, all interactions will be
    // tracked as page views. Receives the new route as an argument, and
    // returns either an object of keyword, category (optional) and resultsCount
    // (optional) to track as a site search, or a falsey value to track as a page
    // view.
    // Default: false, i.e. track all interactions as page views
    // Example: (to) => {
    //   if (to.query.q && to.name === 'search') {
    //     return { keyword: to.query.q, category: to.params.category }
    //   } else {
    //    return null
    //   }
    // }
    trackSiteSearch: false,
});

Vue.config.productionTip = false;

import VueMeta from "vue-meta";
Vue.use(VueMeta);

Vue.filter("ts_ago", function (value) {
    // format a timestamp (seconds since start of Epoch) to a relative time format
    // e.g. 1647504122 -> 3 hours ago

    if (isNaN(value)) {
        // not a number
        return value;
    }

    const secondsSinceEpoch = Math.round(Date.now() / 1000);
    let diff = secondsSinceEpoch - value;

    let years = Math.floor(diff / (365 * 24 * 60 * 60));
    diff = diff - years * (365 * 24 * 60 * 60);
    let months = Math.floor(diff / (30 * 24 * 60 * 60));
    diff = diff - months * (30 * 24 * 60 * 60);
    let days = Math.floor(diff / (24 * 60 * 60));
    diff = diff - days * (24 * 60 * 60);
    let hours = Math.floor(diff / (60 * 60));
    diff = diff - hours * (60 * 60);
    let minutes = Math.floor(diff / 60);
    diff = diff - minutes * 60;
    let seconds = diff;

    let yrs = years == 1 ? "year" : "years";
    let mth = months == 1 ? "month" : "months";
    let day = days == 1 ? "day" : "days";
    let hrs = hours == 1 ? "hour" : "hours";
    let min = minutes == 1 ? "minute" : "minutes";
    let sec = seconds == 1 ? "second" : "seconds";

    if (years > 0) return `${years} ${yrs} ${months} ${mth} ${days} ${day} ago`;
    if (months > 0) return `${months} ${mth} ${days} ${day} ago`;
    if (days > 0) return `${days} ${day} ${hours} ${hrs} ago`;
    if (hours > 0) return `${hours} ${hrs} ${minutes} ${min} ago`;
    return `${minutes} ${min} ${seconds} ${sec} ago`;
});

function ts_iso_format(value) {
    // format timestamp in RFC 3339 format to human-facing format
    // e.g. 1985-04-12T23:20:50.52Z -> 1985-04-12 23:20:50
    if (typeof value === "undefined" || value == null) {
        return "never";
    }
    var index = value.indexOf(".");
    return value.replace("T", " ").slice(0, index);
}

function ts_format(value) {
    // format timestamp in seconds since start of Epoch to human-facing format
    // e.g. 1647504122 -> March 17, 2022 9:02:02 AM
    if (typeof value === "undefined" || value == null || value == 0) {
        return "never";
    }
    var dateObj = new Date(value * 1000);
    return ts_iso_format(dateObj.toISOString());
}

Vue.filter("ts_format", ts_format);
Vue.filter("ts_iso_format", ts_iso_format);

new Vue({
    // store,
    router,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");
