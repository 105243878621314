<template>
    <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
        <AppBar v-if="showNav" :breadcrumb="breadcrumb" />
        <v-main>
            <NavigationDrawer v-if="showNav" />
            <router-view @breadcrumb="breadcrumb = $event" :key="$route.fullPath" class="py-0" />
        </v-main>
    </v-app>
</template>

<script>
import { ref, onBeforeUpdate } from "@vue/composition-api";
import AppBar from "@/components/AppBar.vue";
import NavigationDrawer from "@/components/SideNavigation.vue";

import { useConstants } from "@/const/const.js";
import { useStore } from "@/store/index.js";

const constants = useConstants();

export default {
    props: {},
    components: {
        AppBar,
        NavigationDrawer,
    },
    setup() {
        const store = useStore();

        const breadcrumb = ref([]);
        const showNav = ref();

        if (process.env.NODE_ENV === "development") console.log(store);


        onBeforeUpdate(() => {
            showNav.value = store.state.session.isLoggedIn && !store.state.session.is404;
            console.log("showNav: " + showNav.value);
        });

        return {
            breadcrumb,
            theme: "dark",
            store,
            showNav,
        };
    },
    metaInfo() {
        return {
            title: constants.app_name,
            titleTemplate: `%s | ${constants.app_url}`,
            htmlAttrs: {
                lang: "en-US",
            },
            meta: [
                { charset: "utf-8" },
                { name: "description", content: "Remotely-triggered black hole routing to protect your network from threats, spammers, scammers, botnets and more." },
                { name: "viewport", content: "width=device-width, initial-scale=1" },
                { name: "keywords", content: "network security, network black hole, BGP black hole, BGP blackhole, RTBH, Remotely-Triggered Black Hole Routing, IP blocklist" },
            ],
        };
    },
};
</script>
