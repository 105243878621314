<template>
    <v-app-bar app :style="{ background: $vuetify.theme.themes.dark.background }" clipped-left elevation="0">
        <!-- keep width in sync with the width of SideNavigation -->
        <v-card class="text-h6 ma-n4 pl-4 pr-0" width="200" color="transparent">
            <strong class="myfont">{{ constants.app_name }}</strong>
            <div class="text-caption">v{{ version }}</div>
        </v-card>
        <!-- <div class="mx-8"> -->
            <v-breadcrumbs :items="breadcrumb" class="text-uppercase font-weight-medium mx-10" />
        <!-- </div> -->
        <v-spacer />
        <AddressSearch />
        <v-btn @click="logout" text>
            <span class="mr-2 text-none">Sign out</span>
            <v-icon small>mdi-logout</v-icon>
        </v-btn>
    </v-app-bar>
</template>

<script>
import AddressSearch from "@/components/AddressSearch.vue";
import axios from "axios";
import { useConstants } from "@/const/const.js";
// import { useRouter } from "vue2-helpers/vue-router";
// import { useStore } from "@/store/index.js";
import { version } from '@/../package.json';

export default {
    props: {
        breadcrumb: Array,
    },
    components: {
        AddressSearch,
    },
    setup() {
        const constants = useConstants();
        // const store = useStore();
        // const router = useRouter();

        function logout() {
            axios.post("/api/v1/auth/logout").then(() => {
                // store.commit("change", {isLoggedIn: false });
                window.location.href = "/login";
            });
        }

        return {
            constants,
            logout,
            version,
        };
    },
};
</script>
