import Vue from "vue";
import VueRouter from "vue-router";
// import { createRouter, createWebHistory } from "vue-router";

import VueCompositionAPI from "@vue/composition-api";
Vue.use(VueCompositionAPI);

Vue.use(VueRouter);

import { useStore } from "@/store/index.js";

const store = useStore();

import axios from "axios";
// README https://stackoverflow.com/questions/68256028/forbidden-csrf-cookie-not-set-when-sending-post-delete-request-from-vue-js-to
axios.defaults.xsrfHeaderName = "x-csrftoken";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.withCredentials = true;

import { reactive } from "@vue/composition-api";

const routes = [
    // outside of logged-in perimeter
    {
        path: "/lost-password",
        name: "LostPassword",
        component: () => import("@/views/LostPasswordView.vue"),
    },
    {
        path: "/password-reset/:code",
        name: "PasswordReset",
        component: () => import("@/views/PasswordResetView.vue"),
    },
    {
        path: "/confirm-email/:code",
        name: "ConfirmEmail",
        component: () => import("@/views/ConfirmEmailView.vue"),
    },
    {
        path: "/confirm-invite/:code",
        name: "ConfirmInvite",
        component: () => import("@/views/ConfirmInviteView.vue"),
    },
    // inside of logged-in perimeter
    {
        path: "/login",
        name: "Login",
        component: () => import("@/views/LoginView.vue"),
    },
    {
        path: "/",
        name: "Dashboard",
        component: () => import("@/views/DashboardView.vue"),
    },
    {
        path: "/profiles/:profile_guid?",
        name: "Profiles",
        component: () => import("@/views/ProfilesView.vue"),
    },
    {
        path: "/peers/:peer_guid?",
        name: "Peers",
        component: () => import("@/views/PeersView.vue"),
    },
    {
        path: "/help",
        name: "Help",
        component: () => import("@/views/HelpView.vue"),
    },
    {
        path: "/templates/:peer_guid?",
        name: "Templates",
        component: () => import("@/views/TemplatesView.vue"),
    },
    {
        path: "/orgadmin",
        name: "OrgAdmin",
        component: () => import("@/views/OrgAdminView.vue"),
    },
    {
        path: "/subscription",
        name: "Subscription",
        component: () => import("@/views/SubscriptionView.vue"),
    },
    {
        path: "/filters/:filter_guid?",
        name: "Filters",
        component: () => import("@/views/FiltersView.vue"),
    },
    {
        path: "*",
        name: "NotFound",
        component: () => import("@/views/NotFoundView.vue"),
      }
];

//vue 2:
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

//vue 3:
// const router = createRouter({
//     history: createWebHistory(process.env.BASE_URL),
//     routes,
//   });

// using return true/false/{ name: "Login" } did not work
// next() must be used exactly once per logical path through code

var session = reactive({
    username: "unknown",
    isLoggedIn: false,
    is404: false,
    isOrgAdmin: false,
    displayTrialInfo: false,
    isStripeCustomer: false,
});

router.beforeEach((to, from, next) => {
    // views that don't require login
    // if (["Login", "LostPassword", "PasswordReset", "ConfirmEmail", "ConfirmInvite"].includes(to.name)) {
        // store.commit("change", session);
        // next();
    // } else {
        axios
            .get("/api/v1/auth/session")
            .then((response) => {
                session.username = response.data.username;
                session.isLoggedIn = true;
                session.isOrgAdmin = response.data.isOrgAdmin;
                session.displayTrialInfo = response.data.displayTrialInfo;
                session.isStripeCustomer = response.data.isStripeCustomer;

                store.commit("change", session);

                if (["NotFound"].includes(to.name)) {
                    session.is404 = true;
                }

                if (session.isLoggedIn && to.name == "Login") {
                    next("/");
                } else {
                    next();
                }
            })
            .catch(() => {
                store.commit("change", session);
                if (["Login", "ConfirmEmail", "ConfirmInvite", "PasswordReset", "LostPassword", "NotFound"].includes(to.name)) {
                    // outside perimeter; Logged in, invite/email confirmations
                    next();
                } else {
                    next("/login");
                }
            });
    // }
});

function useSession() {
    return session;
}

export { useSession, router };
