import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

// import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

const vuetify = new Vuetify({
    theme: {
        themes: {
            dark: {
                // primary: colors.blue,
                // background: "#aa0000",
                // background: "#000000",
                background: "#121212",
                white: "#b3b3b3", // rgb(b3b3b3) = rgba(255, 255, 255, 0.7)
            },
        },
        dark: true,
        options: {
            customProperties: true,
        },
    },
});

export default vuetify;
