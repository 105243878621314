import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        session: {},
    },
    mutations: {
        change(state, session) {
            state.session = session;
        },
    },
    getters: {
        session: (state) => state.session,
    },
    plugins: [createPersistedState()],
});

export default store;

//to make the store globally accessible
export function useStore() {
    return store;
}
