<template>
    <v-card flat width="550" class="d-inline-flex align-center" color="transparent">
        <v-text-field
            v-model="term"
            :errorMessages="errorMessage"
            placeholder="Search address or prefix... (e.g. 203.0.113.0/24, 2001:db8::1)"
            dense
            full-width
            flat
            rounded
            class="addressSearchField rounded-xl ml-6 mt-4"
            background-color="grey darken-3"
            prepend-inner-icon="mdi-magnify"
            @keyup="scheduleSubmit($event)"
            @click="notFound=false;searchResultShow=false"
            @input="notFound=false;searchResultShow=false"
        />
        <v-tooltip v-model="notFound" bottom nudge-bottom="30" attach=".addressSearchField">
            The IP address was not found in any filter.
            <v-btn plain @click="notFound=false">
                <v-icon right>mdi-close-circle-outline</v-icon>
            </v-btn>
        </v-tooltip>
        <v-tooltip v-model="searchResultShow" bottom attach=".addressSearchField">
            The IP address was found in the following filters:
            <v-list rounded dense v-if="searchResult !== ''">
                <v-list-item v-for="item in searchResult" :key="item.guid">
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item>
            </v-list>
            <div v-else>(no filters)</div>
        </v-tooltip>
    </v-card>
</template>

<script>
import { ref } from "@vue/composition-api";
import axios from "axios";

export default {
    props: {},
    components: {},
    setup() {
        const term = ref("");
        const errorMessage = ref("");
        const searchResult = ref("");
        const searchResultShow = ref(false);
        const searchScheduler = ref(0);
        const notFound = ref(false);

        function scheduleSubmit(event) {
            // clearing input field clears all errors
            if (term.value === "") {
                errorMessage.value = "";
                notFound.value = false;
                searchResultShow.value = false;
            }

            // Enter submits immediately
            if (event.key === "Enter") {
                search();
                return;
            }

            // otherwise clear scheduler and schedule a new search
            clearTimeout(searchScheduler.value);

            searchScheduler.value = setTimeout(() => {
                search();
            }, 3000);
        }

        function search() {
            // if user hit Enter before the scheduled submit fired
            clearTimeout(searchScheduler.value);
            if (term.value === "") {
                return;
            }

            searchResult.value = "";
            const postData = {
                term: term.value,
                result_type: "filters",
            };
            axios
                .post("/api/v1/search", postData)
                .then((response) => {
                    if (response.data.error == false) {
                        if (response.data.result.length > 0) {
                            searchResult.value = response.data.result;
                            errorMessage.value = "";
                            searchResultShow.value = true;
                        } else {
                            notFound.value = true;
                            errorMessage.value = "";
                        }
                    } else {
                        errorMessage.value = response.data.message;
                    }
                    setTimeout(() => {
                        searchResultShow.value = false;
                    }, 8000);
                })
                .catch((error) => {
                    errorMessage.value = error.response.data.message;
                });
        }

        return {
            search,
            scheduleSubmit,
            term,
            errorMessage,
            searchResult,
            searchResultShow,
            searchScheduler,
            notFound,
        };
    },
};
</script>
