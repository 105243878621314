<template>
    <v-navigation-drawer
        app
        clipped
        permanent
        v-model="shown"
        ref="drawer"
        floating
        :width="width"
        nav
        :style="{ background: $vuetify.theme.themes.dark.background }"
    >
        <v-list dense class="px-0" shaped>
            <v-list-item
                v-for="item in nav_items"
                :key="item.title"
                router
                :to="item.route"
                class="px-4 transparent"
                active-class="primary--text text--accent-4"
            >
                <v-list-item-icon class="mr-4 px-2 py-5">
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import { ref, computed } from "@vue/composition-api";
import { useStore } from "@/store/index.js";

export default {
    props: {},
    components: {},
    setup() {
        const store = useStore();

        const width = ref(200); // keep width in sync with the width of v-card in AppBar
        const shown = ref(true);
        const items = [
            { title: "Dashboard", icon: "mdi-view-dashboard", route: "/", condition: true },
            { title: "Filters", icon: "mdi-format-list-bulleted", route: "/filters", condition: true },
            { title: "Profiles", icon: "mdi-form-select", route: "/profiles", condition: true },
            { title: "Peers", icon: "mdi-router", route: "/peers", condition: true },
            { title: "Templates", icon: "mdi-console-line", route: "/templates", condition: true },
            { title: "Administration", icon: "mdi-shield-crown-outline", route: "/orgadmin", condition: store.state.session.isOrgAdmin ? true : false },
            { title: "Subscription", icon: "mdi-cash-multiple", route: "/subscription", condition: store.state.session.isOrgAdmin ? true : false },
            { title: "My Account", icon: "mdi-account-circle-outline", route: "/myaccount", condition: false },
            { title: "Help", icon: "mdi-help-circle-outline", route: "/help", condition: false },
        ];

        const nav_items = computed(() => items.filter((x) => x.condition));
        return {
            shown,
            width,
            nav_items,
        };
    },
};
</script>
